import configs from './config.json';
import Axios from 'axios';

export class Api {



    static post = async (url, routeParameter, queryString, data, response, errorHandler,onProgress, header = configs.requestHeader) => {
        await Axios.post(`${configs.server.Api}/${url}${routeParameter ? `/${routeParameter}` : ''}${queryString ? `?${queryString}` : ''}`, data,
            {
                headers: header,
                onUploadProgress: progressEvent => {
                    if (onProgress) onProgress( Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(result => {
            response(result);
        }).catch((error) => {

            this.defaultErrorHandler(error, errorHandler)
        });
    };


    static get = async (url, routeParameter, queryString, response, errorHandler, header = configs.requestHeader) => {


        await Axios.get(`${configs.server.Api}/${url}${routeParameter ? `/${routeParameter}` : ''}${queryString ? `?${queryString}` : ''}`, {headers: header})
            .then(result => {
                response(result)
            })
            .catch(e => this.defaultErrorHandler(e, errorHandler));

    };


    static put = async (url, routeParameter, queryString, data, res, errorHandler, header = configs.requestHeader) => {


        await Axios.put(`${configs.server.Api}/${url}${routeParameter ? `/${routeParameter}` : ''}${queryString ? `?${queryString}` : ''}`, data, {headers: header}).then(result => {
            res(result)
        }).catch(e => this.defaultErrorHandler(e, errorHandler));
    };
    static delete = async (url, routeParameter, queryString, data, res, handleError = this.defaultErrorHandler, header = configs.requestHeader) => {
        await Axios.delete(`${configs.server.Api}/${url}${routeParameter ? `/${routeParameter}` : ''}${queryString ? `?${queryString}` : ''}`, {
            headers: header,
            data: data
        }).then(result => {
            res(result)
        }).catch(e => handleError(e));
    }
    static  defaultErrorHandler = (error, errorHandler) => {
        // console.log(error.response.status);
        errorHandler(error);
        if (error.response) {
            switch (error.response.status % 100) {
                case 4:

                    break;
                case 500:

                    break;

                default:

            }
        } else if (error.request) {
            this.connectionError();
        } else {

        }
    }

    static  error404 = () => {
        this.goTo("/404");
    }

    static error500 = () => {
        this.goTo("/500");
    }

    static connectionError = () => {

    }

    static goTo = (address) => {
        // this.#history.push(address);
    }
}