import {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import { MdErrorOutline } from "react-icons/md";

const Icon = ({iconName, props, type}) => {
    const [icon, setIcon] = useState()
    useEffect(() => {
        (async () => {
            let i = await x(type);
            setIcon(i);
        })();
    }, [iconName, type]);


    const I = useMemo(() => (icon ? icon[iconName] : false) || MdErrorOutline, [icon, iconName]);
    return <I {...props}/>;

};

const IconList = ({type,set}) => {
    const [icons, setIcons] = useState({})
    useEffect(() => {
        (async () => {
            const i = await x(type);
            setIcons(i);
        })();
    }, [  type]);
    set(icons ? Object.keys(icons) : []);

    return <></>;
}


const iconFamilies=["wi", "vsc", "ti", "sl", "tfi", "tb", "si", "ri", "rx", "pi", "md", "lu", "io5", "io", "lia", "im", "hi", "hi2", "gr", "go", "gi", "fa6", "fa", "fc", "fi", "di", "ci", "bi", "bs", "ai"];
const x = async (type) => {

    switch (type) {
        case "ai":
            return await import('react-icons/ai');
        case "bs":
            return await import('react-icons/bs');
        case "bi":
            return await import('react-icons/bi');
        case "ci":
            return await import('react-icons/ci');
        case "di":
            return await import('react-icons/di');
        case "fi":
            return await import('react-icons/fi');
        case "fc":
            return await import('react-icons/fc');
        case "fa":
            return await import('react-icons/fa');
        case "fa6":
            return await import('react-icons/fa6');
        case "gi":
            return await import('react-icons/gi');
        case "go":
            return await import('react-icons/go');
        case "gr":
            return await import('react-icons/gr');
        case "hi":
            return await import('react-icons/hi');
        case "hi2":
            return await import('react-icons/hi2');
        case "im":
            return await import('react-icons/im');
        case "lia":
            return await import('react-icons/lia');
        case "io":
            return await import('react-icons/io');
        case "io5":
            return await import('react-icons/io5');
        case "lu":
            return await import('react-icons/lu');
        case "md":
            return await import('react-icons/md');
        case "pi":
            return await import('react-icons/pi');
        case "rx":
            return await import('react-icons/rx');
        case "ri":
            return await import('react-icons/ri');
        case "si":
            return await import('react-icons/si');
        case "tb":
            return await import('react-icons/tb');
        case "tfi":
            return await import('react-icons/tfi');
        case "sl":
            return await import('react-icons/sl');
        case "ti":
            return await import('react-icons/ti');
        case "vsc":
            return await import('react-icons/vsc');
        case "wi":
            return await import('react-icons/wi');

    }
}


Icon.propTypes = {
    type: PropTypes.oneOf(iconFamilies).isRequired,
    iconName: PropTypes.string.isRequired,
    props: PropTypes.object
}


Icon.defaultProps = {
    type: "md",
    iconName: "ErrorOutline",
    props: {}
}
export default Icon;

export {IconList,iconFamilies,x} ;