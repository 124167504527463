import {Api} from "./Api";
import configs from './config.json';


export class AgentUserApi {
    static address = "agentUser";

    static getAll = async (query, response, error) => await Api.get(this.address, null, query, response, error, this.getAuthorizedHeader());

    static get = async (id, response, error) => await Api.get(this.address, id, null, response, error, this.getAuthorizedHeader());

    static update = async (id, data, response, error) => await Api.put(this.address, id, null, data, response, error, this.getAuthorizedHeader());

    static insert = async (data, response, error, progress) => await Api.post(this.address, null, null, data, response, error, progress, this.getAuthorizedHeader());

    static delete = async (id, response, error) => await Api.delete(this.address, id, null, null, response, error, this.getAuthorizedHeader());

    static getAuthorizedHeader = () => {
        return {
            ...configs.requestHeader,
            Authorization: `bearer ${localStorage.getItem('adminToken')}`
        };
    }
}