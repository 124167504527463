import img from '../illustration_dashboard.png';
import styles from '../../assets/style/Login.module.scss';
import {useEffect, useState} from "react";
import {useAgentUserAuth} from "../../context/AgentUserAuthContext";
import {Button, Input, Tooltip} from "@nextui-org/react";
import {Card, Grid, Grid2} from "@mui/material";
import Icon from "../../assets/component/Icon";

const Login = () => {
    const [user, setUser] = useState({phone: "", password: ""});
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const auth = useAgentUserAuth();



    useEffect(() => {

    }, []);


    const submitData = async () => {
        const response = () => {

        }

        const error = (e) => {

        }

        await auth.login({...user}, response, error)
    }
    return <div style={{direction:"ltr",textAlign:"left"}}>
        <Grid container  style={{direction:"ltr",textAlign:"left"}}>
            <Grid xs={8} className={styles.imgContainer}>
                <img src={img} alt=""/>
            </Grid>
            <Grid xs={4} className={styles.formContainer}>
                <div style={{width: "100%",display:"flex",justifyContent:"center"}}>
                    <Card className={styles.card} elevation={10}>
                        <Input
                            fullWidth
                            type="text"
                            label="تلفن"
                            value={user.phone}
                            onValueChange={e => setUser({...user, phone: e})}
                            errorMessage={inputErrors.phone && inputErrors.phone.length ? inputErrors.phone[0] : ''}
                            isInvalid={inputErrors.phone}
                            className={styles.textField}
                        />
                        <Input
                            fullWidth
                            label="رمز عبور"
                            type={showPassword ? "text" : "password"}
                            endContent={<Button isIconOnly size="sm" variant="light" radius="lg" color="danger"
                                                onClick={() => setShowPassword(prevState => !prevState)}>{showPassword ?
                                <Icon iconName="PiEyeSlashFill" type="pi" props={{fontSize: "15px"}}/> :
                                <Icon iconName="PiEyeFill" type="pi" props={{fontSize: "15px"}}/>}  </Button>}
                            value={user.password}
                            onValueChange={e => setUser({...user, password: e})}
                            errorMessage={inputErrors.password && inputErrors.password.length ? inputErrors.password[0] : ''}
                            isInvalid={inputErrors.password}
                            className={styles.textField}
                        />

                        <Button color="danger"
                                className={`${styles.btn} w-full`}
                                isLoading={auth.isPending}
                                onPress={submitData}>
                            ورود
                        </Button>
                    </Card>
                </div>
            </Grid>
        </Grid>
    </div>
};

export {Login};