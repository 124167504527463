import {useAdminAuth} from "../context/AdminAuthContext";
import {Backdrop, Container, Grid2} from "@mui/material";
import SideBar from "../assets/component/sideBar/SideBar";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {useSnackbar} from "notistack";
import {Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Progress} from "@nextui-org/react";


const Main = () => {
    const auth = useAdminAuth();
    const {enqueueSnackbar} = useSnackbar();


    useEffect(() => {
        if (auth.isPending)
            enqueueSnackbar("در حال بررسی احراز هویت.", {variant: "warn"});

    }, [auth.isPending]);


    return <div>
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={auth.isPending}

        >
            <div style={{width: "100%", height: "100%"}}>
                <Progress color="danger" size="md" isIndeterminate/>
            </div>
        </Backdrop>
        <div style={{filter: `blur(${auth.isPending ? 10 : 0}px)`}}>
            <Menu/>
            <div style={{
                maxWidth: "100%",
                backgroundColor: "#F6F9FC",
                minHeight: "calc(100vh - 89px)",

            }}>


                <SideBar/>
                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",

                }}>
                    <div style={{
                        width: "79%",
                        maxWidth: "calc(100% - 160px)",
                        minWidth: "calc(100% - 300px)",
                        paddingTop: 40,
                        paddingBottom: 15

                    }}>
                        <Container maxWidth={"lg"}>
                            <Outlet/>
                        </Container>
                    </div>

                </div>
            </div>
        </div>




    </div>
}


const Menu = () => {
    const auth = useAdminAuth();

    return <div
        style={{boxShadow: "0px 4px 16px rgba(43, 52, 69, 0.1)", height: 85, backgroundColor: "#fff", marginBottom: 4}}>
        <div style={{
            display: "flex",
            flexDirection: "row-reverse"
        }}>
            <div style={{
                width: "79%",
                maxWidth: "calc(100% - 160px)",
                minWidth: "calc(100% - 300px)",

            }}>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={6}></Grid2>
                        <Grid2 xs={6} style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            height: 85,
                            alignItems: "center"
                        }}>

                            {auth.user && <Dropdown placement="bottom-end">
                                <DropdownTrigger>
                                    <Avatar
                                        isBordered
                                        as="button"
                                        className="transition-transform"
                                        radius="lg"
                                        src={`https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png`}
                                    />
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Profile Actions" variant="flat">
                                    <DropdownItem key="profile" className="h-14 gap-2">
                                        <p className="font-semibold">{auth.user.name}</p>
                                        <p className="font-semibold">{auth.user.mail}</p>
                                    </DropdownItem>


                                    <DropdownItem key="logout" className="text-danger" color="danger" onPress={auth.logout}>
                                        خروج
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>}
                        </Grid2>

                    </Grid2>
            </div>
        </div>
    </div>
}


export default Main;
