import {Api} from "./Api";
import {UserApi} from "./UserApi";

export class ProvinceApi {
    static address = "city";

    static getProvinces = async ( response, error) => await Api.get(this.address, null, null, response, error,UserApi.getAuthorizedHeader());

    static getCities = async ( province,response, error) => await Api.get(this.address, province, null, response, error,UserApi.getAuthorizedHeader());

    static update=async (id,data,response,error)=>await Api.put(this.address,id,null,data,response,error,UserApi.getAuthorizedHeader());

    static insert=async (data,response,error,progress)=>await Api.post(this.address,null,null,data,response,error,progress,UserApi.getAuthorizedHeader());

    static delete=async (id,response,error)=>await Api.delete(this.address,id,null,null,response,error,UserApi.getAuthorizedHeader());


}
