import PropTypes from "prop-types";
import {Table as T, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";


const Table = ({
                   rows,
                   headers,
                   hideHeader,
                   removeWrapper,
                   emptyContentMessage,
                   isStriped,
                   selectionMode,
                   selectedColor,
                   disabledKeys,
                   disallowEmptySelection,
                   defaultSelectedKeys,
                   onSelectionChange,
                   sortDescriptor,
                   onSortChange,
                   isHeaderSticky,
                   bottomContent,
                   loadingContent,
                   loadingState,
                   classNames,
                   style
               }) => {


    return <T
        classNames={classNames}
        removeWrapper={removeWrapper}
        onSelectionChange={onSelectionChange}
        defaultSelectedKeys={defaultSelectedKeys}
        disallowEmptySelection={disallowEmptySelection}
        disabledKeys={disabledKeys}
        color={selectedColor}
        isStriped={isStriped}
        selectionMode={selectionMode}
        hideHeader={hideHeader}
        onSortChange={onSortChange}
        sortDescriptor={sortDescriptor}
        bottomContent={bottomContent}
        isHeaderSticky={isHeaderSticky}
        style={style}>
        <TableHeader>
            {headers.map((header, index) => <TableColumn key={index}>{header.title}</TableColumn>)}

        </TableHeader>
        <TableBody emptyContent={emptyContentMessage}
                   loadingContent={loadingContent}
                   isLoading={loadingState}>

            {rows.map((row, index) =>
                <TableRow key={index + 1}>
                    {headers.map((header, i) => <TableCell key={i}>{header.cellRender(row, index)}</TableCell>)}
                </TableRow>
            )}

        </TableBody>
    </T>
};

export default Table;

Table.propTypes = {

    rows: PropTypes.array.isRequired,
    headers: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        cellRender: PropTypes.func.isRequired
    })).isRequired,
    hideHeader: PropTypes.bool,
    removeWrapper: PropTypes.bool,
    emptyContentMessage: PropTypes.string,
    isStriped: PropTypes.bool,
    selectionMode: PropTypes.oneOf(["multiple", "single", "none"]),
    selectedColor: PropTypes.oneOf(["default", "primary", "secondary", "success", "warning", "danger"]),
    onSelectionChange: PropTypes.func,
    disabledKeys: PropTypes.arrayOf(PropTypes.number),
    disallowEmptySelection: PropTypes.bool,
    defaultSelectedKeys: PropTypes.arrayOf(PropTypes.number),
    onSortChange: PropTypes.func,
    isHeaderSticky: PropTypes.bool,
    loadingState: PropTypes.bool,
    bottomContent: PropTypes.node,
    loadingContent: PropTypes.node,
    style:PropTypes.object,
    classNames:PropTypes.object,

    sortDescriptor: PropTypes.objectOf(PropTypes.shape({
        column: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(["descending", "ascending"]).isRequired
    }))

}

Table.defaultProps = {
    rows: [],
    headers: [],
    hideHeader: false,
    removeWrapper: false,
    emptyContentMessage: "",
    isStriped: false,
    selectionMode: "none",
    selectedColor: "default",
    style:{},
    classNames:{
        base:{},
        table:{}
    },
    onSelectionChange: () => {
    },
    onSortChange: () => {
    },
    disabledKeys: [],
    disallowEmptySelection: false,
    defaultSelectedKeys: [],
    sortDescriptor: {},
    isHeaderSticky: false,
    loadingState: false,
    bottomContent: <></>,
    loadingContent: <></>,

}