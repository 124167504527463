import styles from './SideBar.module.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {Collapse, Typography} from "@mui/material";
import Icon from "../Icon";

const SideBar = () => {
    const location = useLocation();

    return <div className={styles.container}>

        <div className={styles.logoContainer}>
            {/*<SVG/>*/}
        </div>
        {items.map((item, index) => <MainItem {...item} key={index} pathname={location.pathname}/>)}

    </div>
};

const SideBar2 = () => {
    const location = useLocation();

    return <div className={styles.container}>

        <div className={styles.logoContainer}>
            {/*<SVG/>*/}
        </div>
        {items2.map((item, index) => <MainItem {...item} key={index} pathname={location.pathname}/>)}

    </div>
};

export default SideBar;
export {SideBar2};

const MainItem = ({title, iconName, iconFamily, link, sub, pathname}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    return <div style={{marginTop: 15}}>
        <div onClick={e => {
            if (sub.length) {
                setOpen(prevState => !prevState)
            } else navigate(link)
        }}>
            <div className={styles.item}>
                <div className={styles.icon}>
                    <Icon iconName={iconName} type={iconFamily}/>
                </div>

                <div className={`${styles.title} ${checkRoute({link, sub}, pathname) ? styles.active : ''}`}>
                    <Typography variant="subtitle1" component="p">
                        {title}
                    </Typography>
                </div>

                {sub.length ? <div className={`${styles.arrow} ${open ? styles.rotate : ''}`}>
                    <Icon iconName={"SlArrowLeft"} type={"sl"}/>
                </div> : <div></div>}
            </div>
        </div>
        {sub.length ?
            <Collapse in={open} mountOnEnter unmountOnExit>
                <div className={styles.wrapper}>
                    {sub.map((item, index) => <SubItem {...item} key={index} pathname={pathname}/>)}
                </div>
            </Collapse> : <></>}

    </div>
}

const SubItem = ({title, sub, link, pathname}) => {
    const navigate = useNavigate();

    return <div style={{marginTop: 5}}>
        <div onClick={e => {
            if (sub.length) {
                // setOpen(prevState => !prevState)
            } else navigate(link)
        }}>
            <div className={styles.item}>
                <div className={`${styles.icon} ${checkRoute({link, sub}, pathname) ? styles.active : ''}`}>
                    <Icon iconName="FaRegDotCircle" type="fa"/>
                </div>

                <div className={`${styles.title} ${checkRoute({link, sub}, pathname) ? styles.active : ''}`}>
                    <Typography variant="subtitle1" component="p">
                        {title}
                    </Typography>
                </div>
                {sub.length ? <div className={styles.arrow}>
                    <Icon iconName={"SlArrowLeft"} type={"sl"}/>
                </div> : <div></div>}

            </div>
        </div>
    </div>
}

const items = [

    {
        title: "میزکار",
        iconName: "MdSpaceDashboard",
        iconFamily: "md",
        link: "/",
        sub: []
    },


    {
        title: "نمایندگان",
        iconName: "FaShop",
        iconFamily: "fa6",
        link: "/agents",
        sub: []
    }, {
        title: "فاکتورها",
        iconName: "FaFileInvoiceDollar",
        iconFamily: "fa6",
        link: "/invoices",
        sub: []
    },


    // {
    //     title: "مشتریان",
    //     iconName: "HiMiniUserGroup",
    //     iconFamily: "hi2",
    //     link: "/customer",
    //     sub: []
    // },
    {
        title: "کاربران",
        iconName: "FaUserFriends",
        iconFamily: "fa",
        link: "/user",
        sub: []
    },
    {
        title: "قرعه کشی",
        iconName: "FaGift",
        iconFamily: "fa",
        link: "/lottery",
        sub: []
    }, {
        title: "استان ها",
        iconName: "FaCity",
        iconFamily: "fa6",
        link: "/province",
        sub: []
    },

];

const items2 = [


    {
        title: "فاکتورها",
        iconName: "FaShop",
        iconFamily: "fa6",
        link: "/agent/invoices",
        sub: []
    },


    {
        title: "کاربران",
        iconName: "FaUserFriends",
        iconFamily: "fa",
        link: "/agent/user",
        sub: []
    },

];


const checkRoute = (item, pathname) => {

    if (item.sub.length) {
        for (const itemElement of item.sub) {
            if (checkRoute(itemElement, pathname))
                return true;
        }

    } else {
        if (pathname === item.link)
            return true;
    }
    return false;
}
