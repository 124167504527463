import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {
    Autocomplete, AutocompleteItem,
    Button,
    Chip, Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Spinner, Textarea,
    Tooltip
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import Table from "../assets/component/table/Table";
import tableStyles from "../assets/style/table.module.scss";
import {useParams} from "react-router-dom";
import {ProvinceApi} from "../api/ProvinceApi";
import {convertErrorObject} from "../utils/converts";
import {useSnackbar} from "notistack";


const City = () => {

    const {enqueueSnackbar} = useSnackbar();
    const params=useParams();
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({status: false, id: 0});
    const [city, setCity] = useState({
        name: "",
        lng: "",
        lat: "",
        id:"",
        open: false
    });
    const [inputErrors, setInputErrors] = useState({});
    const [isSending, setIsSending] = useState(false);


    const deleteAgent = async () => {
        const response = ({data}) => {

            // enqueueSnackbar("با موفقیت حذف شد.", {variant: "success"})
            setOpenDeleteDialog({status: false, id: 0});
            setIsSending(false);

                (async () => {
                    await getCities();
                })();
        }

        const error = (e) => {
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                // enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
            setIsSending(false)

        }
        setIsSending(true);

        await ProvinceApi.delete(openDeleteDialog.id, response, error)
    }
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({...openDeleteDialog, status: false});
    };
    const handleOpenDeleteDialog = (id) => {
        setOpenDeleteDialog({status: true, id: id});
    };


    const submitData = async () => {
        const response = ({data}) => {


            (async () => {
                await getCities();
            })();
            handleCloseDialog();
            setIsSending(false);

        }

        const error = (e) => {
            setIsSending(false)
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                // enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
        }
        setIsSending(true)

        if (city.id)
            await ProvinceApi.update(city.id, {
                payload: {
                    ...city
                }
            }, response, error);
        else
            await ProvinceApi.insert({
                payload: {
                    ...city,
                    province:params.id
                }
            }, response, error)
    }

    const handleCloseDialog = () => {
        setCity({
            name: "",
            lng: "",
            lat: "",
            id:"",
            open: false
        });
    }


    const handleOpenNewDialog = () => {
        setCity({
            name: "",
            lng: "",
            lat: "",
            id:"",
            open: true
        });
    }

    const handleOpenUpdateDialog = (row) => {

        setCity({
            name: row.name,
            lng: row.lng,
            lat: row.lat,
            id:row.id,
            open: true
        });
    }

    const getCities = async () => {
        setLoading(true);
        const response = ({data}) => {
            setCities(data);
            setLoading(false);

        }
        const error = () => {
            setLoading(false);
        }

        await ProvinceApi.getCities(params.id, response, error);
    }
    useEffect(() => {
        (async () => {
            await getCities();
        })();
    }, []);
    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.name}</Chip>
            </div>
        },

        {
            title: "عملیات",
            cellRender: (row) => <div>

                <Tooltip content={"ویرایش شهر"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenUpdateDialog(row)}>
                        <Icon iconName='MdModeEdit' type="md"/>
                    </Button>
                </Tooltip>


                <Tooltip content={"حذف شهر"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenDeleteDialog(row.id)}>
                        <Icon iconName='MdOutlineDeleteOutline' type="md"/>
                    </Button>
                </Tooltip>

            </div>
        },

    ];

    return (<div>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="body1">
                    شهر ها
                </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button variant="light" color="danger"
                            onClick={() => handleOpenNewDialog()}
                            startContent={<Icon iconName="IoIosAdd" type="io"/>}>
                        شهر جدید
                    </Button>
                </div>
            </Grid>

            <Grid xs={12}>
                <Table
                    classNames={{base: tableStyles.base, table: tableStyles.table}}
                    rows={cities}
                    loadingState={loading}
                    emptyContentMessage={"...."}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    headers={headers}
                />
            </Grid>
        </Grid>

        <Modal
            size="sm"
            isOpen={openDeleteDialog.status}
            onClose={handleCloseDeleteDialog}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">حذف کاربر</ModalHeader>
                        <ModalBody>
                            <p>
                                آیا از حذف کاربر مطمئن هستید؟
                            </p>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={deleteAgent} isLoading={isSending}>
                                حذف
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>

        <Modal
            isOpen={city.open}
            onOpenChange={handleCloseDialog}
            placement="top-center"
            size="3xl"
            scrollBehavior="inside"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1">شهر {city.id ? "بروزرسانی" : "جدید"}   </ModalHeader>
                        <ModalBody>

                            <Grid container spacing={1}>
                                <Grid xs={6}>
                                    <div style={{marginTop: 10}}>
                                        <Input
                                            label="نام"
                                            value={city.name}
                                            onValueChange={e => setCity({...city, name: e})}
                                            errorMessage={inputErrors.name && inputErrors.name.length ? inputErrors.name[0] : ''}
                                            isInvalid={inputErrors.name}
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <Input
                                            label="Lng"
                                            value={city.lng}
                                            onValueChange={e => setCity({...city, lng: e})}
                                            errorMessage={inputErrors.lng && inputErrors.lng.length ? inputErrors.lng[0] : ''}
                                            isInvalid={inputErrors.lng}
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <Input
                                            label="Lat"
                                            value={city.lat}
                                            onValueChange={e => setCity({...city, lat: e})}
                                            errorMessage={inputErrors.lat && inputErrors.lat.length ? inputErrors.lat[0] : ''}
                                            isInvalid={inputErrors.lat}
                                        />
                                    </div>


                                </Grid>


                                <Grid xs={6}>


                                </Grid>
                            </Grid>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={submitData} isLoading={isSending}>
                                {city.id ? "بروزرسانی" : "اضافه کردن"}

                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </div>)
}


export default City;
