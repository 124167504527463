import configs from "./config.json";
import {Api} from "./Api";


export class AdminAuthApi {
    static address = "auth/admin";

    static login = async (data, response, error) => await Api.post(`${AdminAuthApi.address}/login`, null, null, data, response, error, null);

    static checkAndRefresh = async (response, error) => await Api.post(`${AdminAuthApi.address}/check`, null, null, null, response, error, null, this.getAuthorizedHeader());


    static logout = async (response, error) => await Api.post(`${AdminAuthApi.address}/logout`, null, null, null, response, error, null, this.getAuthorizedHeader());


    static getAuthorizedHeader = () => {
        return {
            ...configs.requestHeader,
            Authorization: `bearer ${localStorage.getItem('adminToken')}`
        };
    }

}