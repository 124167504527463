import { useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {
    Chip,
    Pagination,
    Spinner,
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import Table from "../assets/component/table/Table";
import {CustomerApi} from "../api/CustomerApi";

const Customers = () => {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useSearchParams();
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);

    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                {row.name}


            </div>
        },

        {
            title: "تلفن",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="BsFillPhoneFill" type="bs"/>}>{row.phone}</Chip>
            </div>
        },

        {
            title: "امتیاز",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="BsFillPhoneFill" type="bs"/>}>{row.phone}</Chip>
            </div>
        },
        {
            title: "وضعیت",
            cellRender: (row) => <div style={{display: "flex"}}>

            </div>
        },

    ];

    const fetchData = async (page) => {
        setLoading(true);
        const response = ({data}) => {
            setPageCount(parseInt(data.last_page));
            setLoading(false);
            setRows(data.data);
        }

        const error = () => {
            setLoading(false);
        }

        await CustomerApi.getAll(`page=${page}`, response, error)
    }



    useEffect(() => {
        (async () => {
            await fetchData(currentPage.get("page") ? currentPage.get("page") : 1);
        })();
    }, [currentPage]);

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    مشتریان
                </Typography>
            </Grid>

            <Grid item  xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    radius="lg"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid  item xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    {/*<Button variant="light" color="danger"*/}
                    {/*        onClick={handleOpenNewDialog}*/}
                    {/*        startContent={<Icon iconName="IoIosAdd" type="io"/>}>*/}
                    {/*    Add Customer*/}
                    {/*</Button>*/}
                </div>
            </Grid>

            <Grid item  xs={12}>
                <Table
                    rows={rows}
                    loadingState={loading}
                    emptyContentMessage={"No Customer"}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    bottomContent={
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Pagination
                                total={pageCount}
                                page={currentPage["page"] ? parseInt(currentPage["page"]) : 1}
                                onChange={e => navigate(`/customer?page=${e}`)}
                                size="sm"
                                color="danger"
                                variant="light"/>

                        </div>
                    }
                    headers={headers}/>
            </Grid>
        </Grid>



    </div>
};

export default Customers;