import {Api} from "./Api";
import {AdminAuthApi} from "./AdminAuthApi";


export class CustomerApi {
    static address = "customer";

    static getAll = async (query, response, error) => await Api.get(this.address, null, query, response, error);

    static get = async (id,query, response, error) => await Api.get(this.address, id, query, response, error);

    static update = async (id, data, response, error) => await Api.put(this.address, id,null,  data,response, error, AdminAuthApi.getAuthorizedHeader());

    static insert = async (data, response, error, progress) => await Api.post(this.address, null, null, data, response, error, progress, AdminAuthApi.getAuthorizedHeader());

    static delete = async (id, response, error) => await Api.delete(this.address, id, null, null, response, error, AdminAuthApi.getAuthorizedHeader());

}