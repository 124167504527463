import {IMask} from "react-imask";

const convertErrorObject = (errors, removeFromKeys = 'payload.') => {
    let output = {};
    let keys = Object.keys(errors);
    for (const x in keys) {
        output = {...output, [keys[x].replace(removeFromKeys, '')]: errors[keys[x]]}
    }
    return output;
}

const convertUniversalDate2PersianDate = (date) => {
    return new Date(date).toLocaleDateString('fa-IR')
}

const getTimeFromDateTime = (date) => {
    return new Date(date).toLocaleTimeString('fa-IR')
}


const getDateTimeFromDateTime = (date) => {
    return new Date(date).toLocaleString('fa-IR')
}



const json2QueryString = (json) => {

    return encodeURIComponent(JSON.stringify(json))
}


const getIdArrayFromObjectArray = (array) => {

    return array.map(item => `${item.id}`);
}

const decodeQueryStringToJson = (encodedString) => {
    return JSON.parse(decodeURIComponent(encodedString));
}


const MobileMask = IMask.createPipe({
    mask: "(#900) 000-0000",
    scale: 2,
    overwrite: true,
    definitions: {'#': /[0]/}
});



const ZipcodeMask = IMask.createPipe({
    mask: "000-000-000-0",
    scale: 2,
    overwrite: true,
});



const convert2EnglishNo = str => {
    str = str.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
    str = str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
    return str;
}


const repairPhoneNo = (phone) => {
    if (phone) {
        phone = phone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
    }
    return phone;
}


const repairZipcode = (zipcode) => {
    if (zipcode) {
        zipcode = zipcode.replace("-", "").replace("-", "").replace("-", "");
    }
    return zipcode;
}

const findMissingItems = (array, jsonObject) => {
    let jsonKeys = Object.keys(jsonObject);

    // Filter array items that do not exist in JSON keys
    return array.filter(function (item) {
        return !jsonKeys.includes(item);
    });
}

function thousandFormatNumber(num) {
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
}
function formatNumber  (x, div = ",")  {
    if (x)
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, div);
    return 0;
}

export {
    formatNumber,
    convertErrorObject,
    json2QueryString,
    getIdArrayFromObjectArray,
    convertUniversalDate2PersianDate,
    getTimeFromDateTime,
    findMissingItems,
    MobileMask,
    convert2EnglishNo,
    repairPhoneNo,
    decodeQueryStringToJson,
    thousandFormatNumber,
    ZipcodeMask,
    repairZipcode,
    getDateTimeFromDateTime
};