import {Api} from "./Api";
import configs from './config.json';


export class LotteryApi {
    static address = "lottery";

    static exec = async (data, response, error) => await Api.post(this.address, null, null, data, response, error, null, this.getAuthorizedHeader());

    static getAuthorizedHeader = () => {
        return {
            ...configs.requestHeader,
            Authorization: `bearer ${localStorage.getItem('adminToken')}`
        };
    }
}