import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Grid, Typography} from "@mui/material";
import {
    Autocomplete, AutocompleteItem,
    Button,
    Chip,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader, Pagination,
    Spinner, Textarea,
    Tooltip, User
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import {convertErrorObject, json2QueryString} from "../utils/converts";
import Table from "../assets/component/table/Table";
import tableStyles from "../assets/style/table.module.scss";
import {AgentApi} from "../api/AgentApi";
import {ProvinceApi} from "../api/ProvinceApi";

const Agents = () => {

    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({status: false, id: 0});
    const [agent, setAgent] = useState({
        name: "",
        address: "",
        manager: "",
        phone: "",
        level:"",
        id: "",
        province: "",
        city: "",
        open: false
    });
    const [inputErrors, setInputErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [pageCount, setPageCount] = useState(0);
    const [province, setProvince] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [loadingCities, setLoadingCities] = useState(false);
    const navigate = useNavigate();


    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({...openDeleteDialog, status: false});
    };
    const handleOpenDeleteDialog = (id) => {
        setOpenDeleteDialog({status: true, id: id});
    };

    const getProvinces = async () => {
        const response = ({data}) => {
            setProvinces(data);
        }
        const error = (e) => {

        }

        await ProvinceApi.getProvinces(response, error);
    }

    const getCities = async (province) => {
        setLoadingCities(true);
        const response = ({data}) => {
            setCities(data);
            setLoadingCities(false);

        }
        const error = () => {
            setLoadingCities(false);
        }

        await ProvinceApi.getCities(province, response, error);
    }

    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.name}</Chip>
            </div>
        },


        {
            title: "نام مدیر",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="HiOutlineMail" type="hi"/>}>{row.manager}</Chip>
            </div>
        },


        {
            title: "شماره تماس",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="HiOutlineMail" type="hi"/>}>{row.phone}</Chip>
            </div>
        },
        {
            title: "سطح",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="HiOutlineMail" type="hi"/>}>{row.level}</Chip>
            </div>
        },


        {
            title: "عملیات",
            cellRender: (row) => <div>
                <Tooltip content={"فاکتورهای نمایندگی"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => navigate(`/invoices-agent/${row.id}`)}>
                        <Icon iconName='FaFileInvoiceDollar' type="fa6"/>
                    </Button>
                </Tooltip>
                <Tooltip content={"کاربران نمایندگی"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => navigate(`/user-agent/${row.id}`)}>
                        <Icon iconName='FaUsers' type="fa"/>
                    </Button>
                </Tooltip>

                <Tooltip content={"ویرایش نمایندگی"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenUpdateDialog(row)}>
                        <Icon iconName='MdModeEdit' type="md"/>
                    </Button>
                </Tooltip>


                <Tooltip content={"حذف نمایندگی"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenDeleteDialog(row.id)}>
                        <Icon iconName='MdOutlineDeleteOutline' type="md"/>
                    </Button>
                </Tooltip>

            </div>
        },

    ];


    const handleCloseDialog = () => {
        setAgent({
            name: "",
            address: "",
            manager: "",
            phone: "",
            id: "",
            level: "",
            province: "",
            city: "",
            open: false
        });
        setCities([]);
        setCity("");
        setProvince("");
    }

    const handleOpenNewDialog = () => {
        setAgent({
            name: "",
            address: "",
            manager: "",
            phone: "",
            id: "",
            level: "",
            province: "",
            city: "",
            open: true
        });
    }

    const handleOpenUpdateDialog = (row) => {
        (async () => {
            await getCities(row.city.province.id);
        })();
        if(row.city){
            setProvince(`${row.city.province.id}`);
            setCity(`${row.city.id}`);
        }
        setAgent({
            name: row.name,
            address: row.address,
            manager: row.manager,
            phone: row.phone,
            id: row.id,
            level: row.level,
            province: "",
            city: "",
            open: true
        });
    }

    const fetchData = async (page) => {
        setLoading(true);
        const response = ({data}) => {
            setLoading(false);
            setRows(data.data);
            setPageCount(parseInt(data.last_page));

        }

        const error = () => {
            setLoading(false);
        }

        await AgentApi.getAll(`page=${page}&attr=${json2QueryString({with: ["city","city.province"]})}`, response, error)
    }

    const deleteAgent = async () => {
        const response = ({data}) => {

            enqueueSnackbar("با موفقیت حذف شد.", {variant: "success"})
            setOpenDeleteDialog({status: false, id: 0});
            setIsSending(false);

            if (parseInt(currentPage.get("page")) === 1)
                (async () => {
                    await fetchData(1);
                })();
            else navigate(`/agents?page=1`);
        }

        const error = (e) => {
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
            setIsSending(false)

        }
        setIsSending(true);

        await AgentApi.delete(openDeleteDialog.id, response, error)
    }

    useEffect(() => {
        (async () => {
            await getProvinces();
        })();
    }, []);

    useEffect(() => {
        if (parseInt(currentPage.get("page")))
            (async () => {
                await fetchData(currentPage.get("page") ? currentPage.get("page") : 1);
            })();
        else navigate(`/agents?page=1`);
    }, [currentPage]);

    useEffect(() => {
        navigate(`/agents?page=1`);
    }, []);

    useEffect(() => {
        if (province)
            (async () => {
                await getCities(province);
            })();
        else {
            setCities([]);
            setCity("");
        }
    }, [province]);

    const submitData = async () => {
        const response = ({data}) => {
            setIsSending(false)

            if (agent.id) {
                let array = Array.from(rows);
                let index = rows.findIndex(row => parseInt(row.id) === parseInt(agent.id));
                if (index > -1)
                    array.splice(index, 1, data);
                setRows(array);
                // enqueueSnackbar("با موفقیت بروزرسانی شد.", {variant: "success"});
            } else {
                // enqueueSnackbar("با موفقیت افزوده شد.", {variant: "success"});
                setRows(prevState => [...prevState, data]);
            }

            handleCloseDialog();
        }

        const error = (e) => {
            setIsSending(false)
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
        }
        setIsSending(true)

        if (agent.id)
            await AgentApi.update(agent.id, {
                payload: {
                    name: agent.name,
                    address: agent.address,
                    phone: agent.phone,
                    level: agent.level,
                    city: city,
                    manager: agent.manager,
                }
            }, response, error);
        else
            await AgentApi.insert({
                payload: {
                    name: agent.name,
                    address: agent.address,
                    phone: agent.phone,
                    level:agent.level,
                    city: city,
                    manager: agent.manager,
                }
            }, response, error)
    }

    return <div>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="body1">
                    نمایندگی ها
                </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button variant="light" color="danger"
                            onClick={() => handleOpenNewDialog()}
                            startContent={<Icon iconName="IoIosAdd" type="io"/>}>
                        نمایندگی جدید
                    </Button>
                </div>
            </Grid>

            <Grid xs={12}>
                <Table
                    classNames={{base: tableStyles.base, table: tableStyles.table}}
                    rows={rows}
                    loadingState={loading}
                    emptyContentMessage={"...."}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    headers={headers}
                    bottomContent={
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Pagination
                                total={pageCount}
                                page={currentPage.get("page") ? parseInt(currentPage.get("page")) :"1"}
                                onChange={e => navigate(`/agents?page=${e}`)}
                                size="sm"
                                color="danger"
                                variant="light"/>

                        </div>
                    }/>
            </Grid>
        </Grid>
        <Modal
            size="sm"
            isOpen={openDeleteDialog.status}
            onClose={handleCloseDeleteDialog}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">حذف کاربر</ModalHeader>
                        <ModalBody>
                            <p>
                                آیا از حذف کاربر مطمئن هستید؟
                            </p>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={deleteAgent} isLoading={isSending}>
                                حذف
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>

        <Modal
            isOpen={agent.open}
            onOpenChange={handleCloseDialog}
            placement="top-center"
            size="3xl"
            scrollBehavior="inside"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1">نمایندگی {agent.id ? "بروزرسانی" : "جدید"}   </ModalHeader>
                        <ModalBody>

                            <Grid container spacing={1}>
                                <Grid xs={6}>
                                    <div style={{marginTop: 10}}>
                                        <Input
                                            label="نام"
                                            value={agent.name}
                                            onValueChange={e => setAgent({...agent, name: e})}
                                            errorMessage={inputErrors.name && inputErrors.name.length ? inputErrors.name[0] : ''}
                                            isInvalid={inputErrors.name}
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>

                                        <Autocomplete
                                            label="استان"
                                            defaultItems={provinces}
                                            className="max-w-[220px]"
                                            selectedKey={province}
                                            onSelectionChange={setProvince}
                                        >
                                            {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                                        </Autocomplete>
                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Autocomplete
                                            label="شهر"
                                            defaultItems={cities}
                                            className="max-w-[220px]"
                                            isLoading={loadingCities}
                                            selectedKey={city}
                                            onSelectionChange={setCity}
                                            errorMessage={inputErrors.city && inputErrors.city.length ? inputErrors.city[0] : ''}
                                            isInvalid={inputErrors.city}
                                        >
                                            {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                                        </Autocomplete>
                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Textarea
                                            label="آدرس"
                                            value={agent.address}
                                            onValueChange={e => setAgent({...agent, address: e})}
                                            rows={4}
                                        />

                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Input
                                            label="نام مدیر"
                                            type="text"
                                            value={agent.manager}
                                            onValueChange={e => setAgent({...agent, manager: e})}
                                            errorMessage={inputErrors.manager && inputErrors.manager.length ? inputErrors.manager[0] : ''}
                                            isInvalid={inputErrors.manager}
                                        />
                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Input
                                            label="تلفن"
                                            type="text"
                                            value={agent.phone}
                                            onValueChange={e => setAgent({...agent, phone: e})}
                                            errorMessage={inputErrors.phone && inputErrors.phone.length ? inputErrors.phone[0] : ''}
                                            isInvalid={inputErrors.phone}
                                        />
                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Input
                                            label="سطح"
                                            type="text"
                                            value={agent.level}
                                            onValueChange={e => setAgent({...agent, level: e})}
                                            errorMessage={inputErrors.level && inputErrors.level.length ? inputErrors.level[0] : ''}
                                            isInvalid={inputErrors.level}
                                        />
                                    </div>

                                </Grid>


                                <Grid xs={6}>


                                </Grid>
                            </Grid>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={submitData} isLoading={isSending}>
                                {agent.id ? "بروزرسانی" : "اضافه کردن"}

                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </div>
};

export default Agents;
