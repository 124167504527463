import React from "react";
import {AuthProvider} from "./UserAuthContext";
import {AuthProvider as AAP} from "./AdminAuthContext";
import {AgentUserAuthProvider} from "./AgentUserAuthContext";

const Provider = ({children}) => {

    return (
        <AuthProvider>
            <AgentUserAuthProvider>
                <AAP>
                    {children}

                </AAP>
            </AgentUserAuthProvider>

        </AuthProvider>

    );
};

export default Provider;
