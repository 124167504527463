import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {
    Button,
    Chip,
    Input, Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    Spinner
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import {CustomerApi} from "../api/CustomerApi";
import Table from "../assets/component/table/Table";
import {LotteryApi} from "../api/LotteryApi";

const Lottery = () => {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [result, setResult] = useState([]);
    const [currentPage, setCurrentPage] = useSearchParams();
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [lottery, setLottery] = useState({count: "", from: "", to: "", open: false});
    const [isSending, setIsSending] = useState(false);
    const [message, setMessage] = useState("");
    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                {row.name}


            </div>
        },

        {
            title: "تلفن",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="BsFillPhoneFill" type="bs"/>}>{row.phone}</Chip>
            </div>
        },

        {
            title: "امتیاز",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="BsFillPhoneFill" type="bs"/>}>{row.phone}</Chip>
            </div>
        },
        {
            title: "وضعیت",
            cellRender: (row) => <div style={{display: "flex"}}>

            </div>
        },

    ];

    const fetchData = async (page) => {
        setLoading(true);
        const response = ({data}) => {
            setPageCount(parseInt(data.last_page));
            setLoading(false);
            setRows(data.data);
        }

        const error = () => {
            setLoading(false);
        }

        await CustomerApi.getAll(`page=${page}`, response, error)
    }


    useEffect(() => {
        (async () => {
            await fetchData(currentPage.get("page") ? currentPage.get("page") : 1);
        })();
    }, [currentPage]);

    const handleOpenNewDialog = () => {
        setLottery({
            count: "", from: "", to: "",
            open: true
        });
        setMessage("");
    }

    const handleCloseDialog = () => {
        setLottery({
            count: "", from: "", to: "",
            open: false
        });

    }

    const submitData = async () => {
        const response = ({data}) => {
            setIsSending(false)
            setResult(data);
            // handleCloseDialog();
        }

        const error = (e) => {

        }
        setIsSending(true)
        // setMessage("مشتری با مقادیر امتیاز مورد نظر یافت نشد!");

        await LotteryApi.exec({payload: lottery}, response, error);

    }

    useEffect(() => {
        console.log(result);
    }, [result]);

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    قرعه کشی
                </Typography>
            </Grid>

            <Grid item xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    radius="lg"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid item xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button variant="light" color="danger"
                            onClick={handleOpenNewDialog}
                    >
                        انجام قرعه کشی
                    </Button>
                </div>
            </Grid>

            <Grid item xs={12}>
                <Table
                    rows={rows}
                    loadingState={loading}
                    emptyContentMessage={"No Customer"}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    bottomContent={
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Pagination
                                total={pageCount}
                                page={currentPage["page"] ? parseInt(currentPage["page"]) : 1}
                                onChange={e => navigate(`/customer?page=${e}`)}
                                size="sm"
                                color="danger"
                                variant="light"/>

                        </div>
                    }
                    headers={headers}/>
            </Grid>
        </Grid>


        <Modal
            isOpen={lottery.open}
            onOpenChange={handleCloseDialog}
            placement="top-center"
            size="3xl"
            scrollBehavior="inside"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1">انجام قرعه کشی </ModalHeader>
                        <ModalBody>

                            <Grid container spacing={1}>
                                <Grid xs={6}>

                                    <div style={{padding: 10}}>
                                        <div style={{marginTop: 10}}>
                                            <Input
                                                label="مقدار امتیاز از"
                                                value={lottery.from}
                                                onValueChange={e => setLottery({...lottery, from: e})}

                                            />
                                        </div>

                                        <div style={{marginTop: 10}}>

                                            <Input
                                                label="مقدار امتیاز تا"
                                                type="text"
                                                value={lottery.to}
                                                onValueChange={e => setLottery({...lottery, to: e})}

                                            />

                                        </div>
                                        <div style={{marginTop: 10}}>

                                            <Input
                                                label="تعداد برنده"
                                                type="text"
                                                value={lottery.count}
                                                onValueChange={e => setLottery({...lottery, count: e})}

                                            />
                                        </div>
                                    </div>


                                </Grid>


                                <Grid xs={6}>
                                    <div style={{padding: 10}}>
                                        <Typography variant="caption">
                                            {message}
                                        </Typography>
                                    </div>
                                    <div style={{padding: 10}}>
                                        {result.map((item, index) => <div style={{
                                            marginTop: 10,
                                            padding: 10,
                                            borderRadius: 10,
                                            border: "1px solid rgb(243,18,96)"
                                        }} key={index}>
                                            <Typography
                                                variant="body1">نام: {item.first_name} {item.last_name} </Typography>

                                            <Typography variant="caption" style={{marginTop: 5}}>پست
                                                الکترونیک: {item.email}</Typography>
                                            <br/>
                                            <Typography variant="caption"
                                                        style={{marginTop: 5}}>تلفن: {item.phone}</Typography>
                                        </div>)}
                                    </div>


                                </Grid>
                            </Grid>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={submitData} isLoading={isSending}>
                                انجام

                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </div>
};

export default Lottery;