import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {InvoiceApi} from "../api/InvoiceApi";
import {convertUniversalDate2PersianDate, formatNumber, json2QueryString} from "../utils/converts";
import logo from '../assets/img/logo.png'
import {Typography} from "@mui/material";
import {Button, Select, SelectItem, Tooltip} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import {useAgentUserAuth} from "../context/AgentUserAuthContext";

const Invoice = () => {
    const params = useParams();
    const auth = useAgentUserAuth();

    const [data, setData] = useState({})
    const fetchData = async () => {
        const response = ({data}) => {
            setData(data);

        }

        const error = () => {
        }

        await InvoiceApi.get(params.id, response, error)
    }


    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [params.id]);

    const print = () => {
        let printContents = document.getElementById("printable-div").innerHTML;


        let innerHTML = `<!DOCTYPE html>
        <html lang="pt-BR">
        <head>   
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <style>
            *{
            direction: rtl;
            text-align: right;
            }
        </style>
        </head>
        <body>`;
        innerHTML += printContents + "</body></html>";
        let fakeIFrame = window.document.createElement("iframe");
        document.body.appendChild(fakeIFrame);
        let fakeContent = fakeIFrame.contentWindow;
        fakeContent.document.open();
        fakeContent.document.write(innerHTML);
        fakeContent.document.close();
        fakeContent.focus();
        fakeIFrame.addEventListener("load", () => {
            fakeContent.print();
            fakeIFrame.remove();

        });

    }
    const changeStatus = async ({target}) => {


        const response = () => {
            (async () => {
                await fetchData();
            })();
        }

        const error = () => {

        }
        await InvoiceApi.update(params.id, {payload: {status: target.value}}, response, error);
    }

    return (<div style={{position: "relative", paddingTop: 80, width: "100%", overflowX: "auto", paddingBottom: 30}}>


        <div style={{position: "absolute", top: 5, right: 5}}>
            <Tooltip content={"چاپ"} placement="top">
                <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                        onClick={print}>
                    <Icon iconName='PiPrinterLight' type="pi"/>
                </Button>
            </Tooltip>
        </div>

        <div style={{position: "absolute", top: 75, right: 5, width: "25%"}}>
            <Select
                label="تعیین وضعیت"

                // selectedKeys={[value]}
                isDisabled={ ((data.hasOwnProperty("agent") && data.agent.length) && (data.agent[0].pivot.status === "delivered" || data.agent[0].pivot.status === "rejected")) ||  (data.hasOwnProperty("agent") &&!data.agent.length)}
                onChange={changeStatus}
            >
                <SelectItem key={"delivered"}>
                    ارسال شده
                </SelectItem>
                <SelectItem key={"accepted"}>
                    در حال آماده سازی
                </SelectItem>
                <SelectItem key={"rejected"}>
                    رد شده
                </SelectItem>
            </Select>
        </div>


        <div style={{minWidth: 1000}} id="printable-div">
            <div style={{width: "100%", justifyContent: "center"}}
            >


                <div style={{
                    float: "left",
                    backgroundColor: "#FFEEF1",
                    borderRadius: 8,
                    width: "25%",
                    marginRight: "5%",
                    padding: 10,
                    textAlign: "center"
                }}>
                    تلفن: {data.hasOwnProperty('address') ? data.address.phone : ""}
                </div>
                <div style={{
                    float: "left",
                    backgroundColor: "#FFEEF1",
                    borderRadius: 8,
                    width: "40%",
                    marginRight: "5%",
                    padding: 10,
                    textAlign: "center"
                }}>
                    نام
                    مشتری:{data.hasOwnProperty('address') ? data.address.first_name + " " + data.address.last_name : ""}
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: 20}}
            >
                <div style={{float: "right", width: "25%"}}>
                    <img src={logo} alt="logo" style={{width: "100px"}}/>
                </div>

                <div style={{
                    clear: "right",
                    backgroundColor: "#FFEEF1",
                    borderRadius: 8,
                    width: "40%",
                    marginRight: "5%",
                    padding: 10,
                    textAlign: "center"
                }}>
                    ارجاع شده به
                    نمایندگی {data.hasOwnProperty('agent') && data.agent.length ? data.agent[0].name : ""} در
                    شهر {data.hasOwnProperty("address") ? data.address.city : ''}
                    {data.hasOwnProperty('agent') ? '' : ""}
                </div>

                <div style={{
                    float: "left",
                    backgroundColor: "#FFEEF1",
                    borderRadius: 8,
                    width: "25%",
                    marginRight: "5%",
                    padding: 10,
                    textAlign: "center"
                }}>
                    تاریخ: {data.hasOwnProperty('date_created_gmt') ? convertUniversalDate2PersianDate(data.date_created_gmt) : ""}
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            <div style={{position: "relative", paddingBottom: 100}}>
                <div style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "25%",
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 20,
                    background: "#19B2001A",
                    border: " 1px solid #475569",
                    minHeight: 150,
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    paddingTop: 50,
                    justifyContent: "center"
                }}>

                    <Typography variant="body1">
                        تلفن پشتیبانی:
                    </Typography>


                    <Typography variant="body1" style={{marginRight: 10}}>
                        021-36900284
                        <br/>
                        021-36900293
                    </Typography>


                </div>

                <div style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "40%",
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 20,
                    background: "#19B2001A",
                    border: " 1px solid #475569",
                    minHeight: 150,
                    zIndex: 1,
                    paddingTop: 50,

                }}>
                    <div style={{
                        marginRight: 10,
                        float: "right",
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <Typography variant="body1">
                            مالیات
                        </Typography>

                        <Typography variant="body1" style={{marginTop: 10}}>
                            جمع کل پس از تخفیف و مالیات
                        </Typography>
                    </div>

                    <div style={{
                        alignItems: "end",
                        float: "left",
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: 10
                    }}>
                        <Typography variant="body1">
                            0 تومان
                        </Typography>

                        <Typography variant="body1" style={{marginTop: 10}}>
                            {formatNumber(data.hasOwnProperty('products') && data.products.length ? data.products.reduce((total, product) =>
                                product.hasOwnProperty('pivot') && product.pivot.hasOwnProperty("product_net_revenue") ? (parseInt(product.pivot.product_net_revenue) * parseInt(product.pivot.product_qty)) + total : total, 0
                            ) : 0)} تومان

                        </Typography>
                    </div>
                </div>
                <div style={{
                    background: "#E5F9F2",
                    border: "1px solid #64748B",
                    borderRadius: 31,
                    marginTop: 20,
                    zIndex: 2,
                    position: "inherit"
                }}>

                    <div>

                    </div>

                    <div style={{padding: 20}}>
                        <div style={{display: "flex", borderBottom: "1px solid #B0B0B0", paddingBottom: 10}}>
                            <div
                                style={{width: "30%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <Typography variant="body1" style={{color: "#404040"}}>
                                    کالا/برند
                                </Typography>
                            </div>

                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%"
                            }}>
                                <Typography variant="body1" style={{color: "#404040"}}>
                                    تعداد
                                </Typography>
                            </div>


                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%"
                            }}>
                                <Typography variant="body1" style={{color: "#404040"}}>
                                    فی
                                </Typography>
                            </div>

                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%"
                            }}>
                                <Typography variant="body1" style={{color: "#404040"}}>
                                    درصد تخفیف
                                </Typography>
                            </div>

                            <div style={{
                                width: "20%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%"
                            }}>
                                <Typography variant="body1" style={{color: "#404040"}}>
                                    مجموع
                                </Typography>
                            </div>
                        </div>


                        <div style={{display: "flex", marginTop: 10}}>
                            <div style={{
                                width: "30%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#FF001F14",
                                boxShadow: "-5px 0px 8.5px 2px #00000040",
                                borderTopRightRadius: 20,
                                borderBottomRightRadius: 20,
                                padding: 10
                            }}>

                                {data.hasOwnProperty('products') && data.products.length ? data.products.map((product, index) =>
                                    <div style={{
                                        marginTop: 8,
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>
                                        <Typography variant="subtitle2" style={{
                                            color: "#404040", textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "1.6em",
                                            whiteSpace: "nowrap"
                                        }} key={index}>
                                            {product.hasOwnProperty('post_title') ? product.post_title : ''}
                                        </Typography>
                                    </div>
                                ) : ""}

                            </div>

                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%",
                                flexDirection: "column",
                                background: "#FF001F14",
                                boxShadow: "-5px 0px 8.5px 2px #00000040",
                                padding: 10
                            }}>
                                {data.hasOwnProperty('products') && data.products.length ? data.products.map((product, index) =>
                                    <div style={{
                                        marginTop: 8,
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>
                                        <Typography variant="subtitle2" style={{
                                            color: "#404040", textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "1.6em",
                                            whiteSpace: "nowrap",
                                            textAlign: "center"
                                        }} key={index}>
                                            {product.hasOwnProperty('pivot') && product.pivot.hasOwnProperty("product_qty") ? product.pivot.product_qty : ''}
                                        </Typography>
                                    </div>
                                ) : ""}
                            </div>

                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%",
                                background: "#FF001F14",
                                boxShadow: "-5px 0px 8.5px 2px #00000040",
                                flexDirection: "column",
                                padding: 10
                            }}>
                                {data.hasOwnProperty('products') && data.products.length ? data.products.map((product, index) =>
                                    <div style={{
                                        marginTop: 8,
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>
                                        <Typography variant="subtitle2" style={{
                                            color: "#404040", textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "1.6em",
                                            whiteSpace: "nowrap",
                                            textAlign: "center"
                                        }} key={index}>
                                            {product.hasOwnProperty('pivot') && product.pivot.hasOwnProperty("product_net_revenue") ? formatNumber(product.pivot.product_net_revenue) : ''}
                                        </Typography>
                                    </div>
                                ) : ""}
                            </div>

                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%",
                                background: "#FF001F14",
                                boxShadow: "-5px 0px 8.5px 2px #00000040",
                                flexDirection: "column",
                                padding: 10
                            }}>
                                {data.hasOwnProperty('products') && data.products.length ? data.products.map((product, index) =>
                                    <div style={{
                                        marginTop: 8,
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>
                                        <Typography variant="subtitle2" style={{
                                            color: "#404040", textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "1.6em",
                                            whiteSpace: "nowrap",
                                            textAlign: "center"
                                        }} key={index}>
                                            0
                                        </Typography>
                                    </div>
                                ) : ""}
                            </div>

                            <div style={{
                                width: "20%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1%",
                                background: "#FF001F14",
                                boxShadow: "-5px 0px 8.5px 2px #00000040",
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                                flexDirection: "column",
                                padding: 10
                            }}>
                                {data.hasOwnProperty('products') && data.products.length ? data.products.map((product, index) =>
                                    <div style={{
                                        marginTop: 8,
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>
                                        <Typography variant="subtitle2" style={{
                                            color: "#404040", textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            height: "1.6em",
                                            whiteSpace: "nowrap",
                                            textAlign: "center"
                                        }} key={index}>
                                            {product.hasOwnProperty('pivot') && product.pivot.hasOwnProperty("product_net_revenue") ? formatNumber(product.pivot.product_net_revenue * product.pivot.product_qty) : ''}
                                        </Typography>
                                    </div>
                                ) : ""}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>)
};

export default Invoice;
