import React, {useEffect, useState} from "react";
import {ProvinceApi} from "../api/ProvinceApi";
import {Grid, Typography} from "@mui/material";
import {
    Autocomplete, AutocompleteItem,
    Button,
    Chip, Input,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    Pagination,
    Spinner, Textarea,
    Tooltip
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import Table from "../assets/component/table/Table";
import tableStyles from "../assets/style/table.module.scss";
import {useNavigate} from "react-router-dom";


const Province = () => {
    const [provinces, setProvinces] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const getProvinces = async () => {
        const response = ({data}) => {
            setProvinces(data);
            setLoading(false);
        }
        const error = (e) => {
            setLoading(false);
        }
        setLoading(true);
        await ProvinceApi.getProvinces(response, error);
    }

    useEffect(() => {
        (async () => {
            await getProvinces();
        })();
    }, []);
    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.name}</Chip>
            </div>
        },

        {
            title: "عملیات",
            cellRender: (row) => <div>

                <Tooltip content={"شهرها"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => navigate(`/city/${row.id}`)}>
                        <Icon iconName='FaCity' type="fa6"/>
                    </Button>
                </Tooltip>


            </div>
        },

    ];

    return (<div>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="body1">
                    استان ها
                </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>

                </div>
            </Grid>

            <Grid xs={12}>
                <Table
                    classNames={{base: tableStyles.base, table: tableStyles.table}}
                    rows={provinces}
                    loadingState={loading}
                    emptyContentMessage={"...."}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    headers={headers}
                />
            </Grid>
        </Grid>


    </div>)
};

export default Province;
