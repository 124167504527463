import {PieChart, pieArcLabelClasses} from '@mui/x-charts/PieChart';
import {LineChart} from "@mui/x-charts";
import {SummaryApi} from "../api/SummaryApi";
import {useEffect, useState} from "react";
import {convertUniversalDate2PersianDate} from "../utils/converts";
import {Card, Typography} from "@mui/material";

const Dashboard = () => {

    const [data, setData] = useState({pie: [], line: []})
    const size = {
        width: 1000,
        height: 600,
    };


    const fetchData = async () => {
        const response = ({data}) => {
            setData(data);
        }
        const error = () => {

        }

        await SummaryApi.getAll(null, response, error);
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    return (
        <div>
            <Card elevation={6} style={{padding: 20,display:"flex",justifyContent:"center",marginTop:20,borderRadius:15,flexDirection:"column"}}>


                <Typography variant="h6">
                    دسته بندی تعداد فاکتورها بر حسب استان
                </Typography>
                <PieChart

                    series={[
                        {
                            arcLabel: (item) => `${item.value}`,
                            innerRadius: 31,
                            outerRadius: 133,
                            paddingAngle: 4,
                            cornerRadius: 10,
                            startAngle: -116,
                            endAngle: 157,
                            // cx: 169,
                            // cy: 170,
                            data: data.pie,
                            highlightScope: { fade: 'global', highlight: 'item' },
                        },
                    ]}
                    slotProps={{
                        legend: { padding:30,itemMarkWidth:25,markGap:80},
                    }}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fontWeight: 'bold',
                        },
                    }}
                    {...size}
                />
            </Card>

            <Card elevation={6} style={{padding: 20,display:"flex",justifyContent:"center",marginTop:20,borderRadius:15,flexDirection:"column"}}>
                <Typography variant="h6">
                    روند فاکتورهای ثبت شده در 30 روز گذشته
                </Typography>

                <LineChart
                    xAxis={[
                        {data: data.line.map(item => convertUniversalDate2PersianDate(item.date)), scaleType: 'point'}
                    ]}
                    series={[
                        {
                            data: data.line.map(item => item.count),
                        },
                    ]}
                    width={1000}
                    height={600}
                />
            </Card>
        </div>
    )
};

export default Dashboard;

