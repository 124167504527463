// contexts/UserAuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {UserAuthApi} from "../api/UserAuthApi";

const UserAuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({status: 'noCheck', error: null, loggedIn: false});
    const [render, setRender] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("adminToken") && state.status !== "loggedIn" && state.status !== "pending" && render)
            (async () => {
                await checkAndRefresh();
            })();
        else if (!localStorage.getItem("adminToken") && render)
            setState({...state, status: "loggedOut"});

    }, [render]);

    useEffect(() => {
        setRender(true);
    }, []);


    const login = async (data, res, er) => {
        const response = async ({data}) => {
            await res(data);
            localStorage.setItem("adminToken", data.accessToken);
            setState({
                status: 'loggedIn', error: null, loggedIn: true
            });
            setUser(data.user);
        }
        const error = (e) => {
            er(e);
            setState({...state, status: "error"})

        }
        setState({...state, status: "pending"})

        await UserAuthApi.login(data, response, error);
    }


    const checkAndRefresh = async () => {
        const response = ({data}) => {
            localStorage.setItem("adminToken", data.accessToken);
            setState({
                status: 'loggedIn', error: null, loggedIn: true
            });
            setUser(data.user);
        }

        const error = (e) => {
            setState({...state, status: "loggedOut"});
            localStorage.removeItem("adminToken");


        }
        setState({...state, status: "pending"})

        await UserAuthApi.checkAndRefresh(response, error);
    }


    const logout = async () => {
        const response = () => {
            setState({...state, status: "loggedOut"});
            localStorage.removeItem("adminToken");
            setUser(null);
        }

        const error = () => {
            setState({...state, status: "loggedOut"});
            localStorage.removeItem("adminToken");
            setUser(null);
        }
        setState({...state, status: "pending"})

        await UserAuthApi.logout(response,error);
    }



    const isPending = state.status === 'pending';
    const isLoggedIn = state.status === "loggedIn";
    const isLoggedOut = state.status === "error" || state.status === "loggedOut";
    return (
        <UserAuthContext.Provider value={{
            isAuthenticated: !!user,
            // isAuthenticated: true,
            user,
            login,
            logout,
            state,
            isPending,
            isLoggedIn,
            isLoggedOut,
            checkAndRefresh
        }}>
            {children}
        </UserAuthContext.Provider>
    );
};

export const useUserAuth = () => useContext(UserAuthContext);
