import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Grid, Typography} from "@mui/material";
import {
    Button, Checkbox,
    Chip, DateRangePicker, Input,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    Pagination,
    Spinner,
    Tooltip
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import {convertUniversalDate2PersianDate, json2QueryString} from "../utils/converts";
import Table from "../assets/component/table/Table";
import tableStyles from "../assets/style/table.module.scss";
import {InvoiceApi} from "../api/InvoiceApi";
import {useAgentUserAuth} from "../context/AgentUserAuthContext";
import {parseDate} from "@internationalized/date";
import configs from '../api/config.json';
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";


const inputSubject = new Subject();


const Invoices = () => {
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({status: false, id: 0});
    const [report, setReport] = useState({open: false, startDate: "", endDate: ""});
    const [isSending, setIsSending] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [pageCount, setPageCount] = useState(0);
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const auth = useAgentUserAuth();
    const [inputValue, setInputValue] = useState('');
    const [conditionValue, setConditionValue] = useState("");
    const location = useLocation();
    const [date, setDate] = useState({
        start: parseDate("2024-04-01"),
        end: parseDate("2024-04-08"),
    })
    const [columns, setColumns] = useState({
        customerName: true,
        customerPhone: true,
        province: true,
        city: true,
        invoiceDate: true,
        invoiceAmount: true,
        invoiceStatus: true,

        agentName: true,
        agentId: true,
        agentManager: true,
        agentPhone: true

    });

    const changeColumn = (name, status) => {
        setColumns(prevState => {
            return {...prevState, [name]: status}
        });
    }
    const params = useParams();
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({...openDeleteDialog, status: false});
    };
    const handleOpenDeleteDialog = (id) => {
        setOpenDeleteDialog({status: true, id: id});
    };

    const statusCheck = (status) => {

        switch (status) {
            case "delivered":
                return "ارسال شده";
            case "accepted":
                return "در حال آماده سازی";
            case "rejected":
                return "رد شده";
            default:
                return "در حال بررسی";
        }

    }
    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام مشتری",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.customer.first_name} {row.customer.last_name}</Chip>
            </div>
        },

        {
            title: "شهر",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.address.city}</Chip>
            </div>
        },

        {
            title: "تلفن مشتری",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.address.phone}</Chip>
            </div>
        },


        {
            title: "زمان",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                >{convertUniversalDate2PersianDate(row.date_created_gmt)}</Chip>
            </div>
        },


        {
            title: "نمایندگی",
            cellRender: (row) => <div>
                {row.agent.length ? row.agent[0].name : ""}
            </div>
        },
        {
            title: "وضعیت",
            cellRender: (row) => <div>
                {row.agent.length ? statusCheck(row.agent[0].pivot.status) : ""}
            </div>
        },
        {
            title: "",
            cellRender: (row) =>
                <Tooltip content={"فاکتور"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => navigate(auth.isAuthenticated ? `/agent/invoice/${row.id}` : `/invoice/${row.id}`)}>
                        <Icon iconName='FaFileInvoiceDollar' type="fa6"/>
                    </Button>
                </Tooltip>
        },


    ];


    const fetchData = async (page) => {
        setLoading(true);
        const response = ({data}) => {
            setLoading(false);
            setRows(data.data);
            setPageCount(parseInt(data.last_page));

        }

        const error = () => {
            setLoading(false);
        }
        let con = location.pathname.includes('/agent/invoices') ? [{
            "type": "whereHas",
            "relation": "agent",
            "orWhere": [],
            "conditions": [

                {
                    "type": "where",
                    "field": "id",
                    "operand": "=",
                    "value": auth.user.agent.id,
                    "orWhere": []
                }

            ]
        }] : [];
        if (params.id)
            con = [{
                "type": "whereHas",
                "relation": "agent",
                "orWhere": [],
                "conditions": [

                    {
                        "type": "where",
                        "field": "id",
                        "operand": "=",
                        "value": params.id,
                        "orWhere": []
                    }

                ]
            }];

        let condition = [];
        if (conditionValue) {
            condition = [
                {
                    type: "where",
                    field: "id",
                    operand: "like",
                    value: `${conditionValue}%`,
                    orWhere: [
                        {
                            "type": "whereHas",
                            "relation": "address",
                            "orWhere": [],
                            "conditions": [
                                {
                                    "type": "where",
                                    "field": "phone",
                                    "operand": "like",
                                    "value": `${conditionValue}%`,
                                    "orWhere": []
                                }
                            ]
                        },
                        {
                            "type": "whereHas",
                            "relation": "address",
                            "orWhere": [],
                            "conditions": [
                                {
                                    "type": "where",
                                    "field": "city",
                                    "operand": "like",
                                    "value": `${conditionValue}%`,
                                    "orWhere": []
                                }
                            ]
                        },
                        {
                            "type": "whereHas",
                            "relation": "address",
                            "orWhere": [],
                            "conditions": [
                                {
                                    "type": "where",
                                    "field": "first_name",
                                    "operand": "like",
                                    "value": `${conditionValue}%`,
                                    "orWhere": []
                                }
                            ]
                        },
                        {
                            "type": "whereHas",
                            "relation": "address",
                            "orWhere": [],
                            "conditions": [
                                {
                                    "type": "where",
                                    "field": "last_name",
                                    "operand": "like",
                                    "value": `${conditionValue}%`,
                                    "orWhere": []
                                }
                            ]
                        },

                    ]
                }
            ]
        }
        await InvoiceApi.getAll(`page=${page}&attr=${json2QueryString({
            with: [],
            condition: [...con, ...condition]
        })}`, response, error)
    }


    useEffect(() => {
        if (parseInt(currentPage.get("page")))
            (async () => {
                await fetchData(currentPage.get("page") ? currentPage.get("page") : 1);
            })();
        else {
            if (location.pathname.includes('/agent/invoices'))
                navigate(`/agent/invoices?page=1`);
            else if(location.pathname.includes('/invoices-agent/'))
                navigate(`/invoices-agent/${params.id}?page=1`)
            else navigate(`/invoices?page=1`);

        }
    }, [currentPage, conditionValue]);


    const handleOpenNewDialog = () => {
        setReport({
            ...report,
            open: true
        });
        setMessage("")
    }

    const handleCloseDialog = () => {
        setReport({
            ...report,
            open: false
        });

    }
    const submitData = async () => {
        const response = ({data}) => {
            setIsSending(false)


            handleCloseDialog();

            if (data) {
                const {
                    hostname, port, protocol
                } = window.location;
                window.location.href = `${configs.isDemo ? '' : `${protocol}//`}${configs.isDemo ? configs.server.Storage : hostname}${!configs.isDemo ? `:${port}/backend/public/report` : ''}/${data}`;

            }
        }

        const error = (e) => {
            setIsSending(false)

        }
        setIsSending(true)
        // setMessage("فاکتوری در بازه زمانی مورد نظر یافت نشد!")

        await InvoiceApi.export({payload: {columns: columns, date: date}}, response, error, null);
    }


    useEffect(() => {
        const subscription = inputSubject
            .pipe(
                debounceTime(1500),
                distinctUntilChanged()
            ) // Optional debounce
            .subscribe(value => {
                // Perform any action you want with the input value
                setConditionValue(value);
            });
    }, []);


    const handleInputChange = event => {
        const value = event;
        inputSubject.next(value);
        setInputValue(value);

    };


    return <div>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="body1">
                    فاکتورها
                </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <Input
                    type="type"
                    label=""
                    className="w-full"
                    size="sm"
                    startContent={<Icon type="tb" iconName="TbSearch"/>}
                    value={inputValue}
                    onValueChange={handleInputChange}
                />
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button variant="light" color="danger"
                            onClick={handleOpenNewDialog}>
                        دریافت خروجی
                    </Button>
                </div>
            </Grid>

            <Grid xs={12} style={{marginTop: 10}}>
                <Table
                    classNames={{base: tableStyles.base, table: tableStyles.table}}
                    rows={rows}
                    loadingState={loading}
                    emptyContentMessage={"No Invoice"}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    headers={headers}
                    bottomContent={
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Pagination
                                total={pageCount}
                                page={currentPage.get("page") ? parseInt(currentPage.get("page")) : "1"}
                                onChange={e => {
                                    if (location.pathname.includes('/agent/invoices'))
                                        navigate(`/agent/invoices?page=${e}`);
                                    else if(location.pathname.includes('/invoices-agent/'))
                                        navigate(`/invoices-agent/${params.id}?page=${e}`)
                                    else navigate(`/invoices?page=${e}`);
                                }}
                                size="sm"
                                color="danger"
                                variant="light"/>

                        </div>
                    }/>
            </Grid>
        </Grid>
        <Modal
            size="sm"
            isOpen={openDeleteDialog.status}
            onClose={handleCloseDeleteDialog}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">حذف فاکتور</ModalHeader>
                        <ModalBody>
                            <p>
                                آیا از حذف مطمئن هستید؟
                            </p>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>

                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
        <Modal
            isOpen={report.open}
            onOpenChange={handleCloseDialog}
            placement="top-center"
            size="3xl"
            scrollBehavior="inside"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1"> دریافت گزارش </ModalHeader>
                        <ModalBody>

                            <Grid container spacing={1}>
                                <Grid xs={6}>
                                    <div style={{marginTop: 10}}>
                                        <DateRangePicker
                                            label="بازه گزارش"
                                            value={date}
                                            onChange={setDate}
                                            color="danger"
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" size="sm" isSelected={columns.customerName}
                                                  onValueChange={(e) => changeColumn("customerName", e)}>نام
                                            مشتری</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.customerPhone}
                                                  onValueChange={(e) => changeColumn("customerPhone", e)} size="sm">تلفن
                                            مشتری</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.province}
                                                  onValueChange={(e) => changeColumn("province", e)}
                                                  size="sm">استان</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.city}
                                                  onValueChange={(e) => changeColumn("city", e)}
                                                  size="sm">شهر</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.invoiceDate}
                                                  onValueChange={(e) => changeColumn("invoiceDate", e)} size="sm">تاریخ
                                            فاکتور</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.invoiceAmount}
                                                  onValueChange={(e) => changeColumn("invoiceAmount", e)} size="sm">مبلغ
                                            فاکتور</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.invoiceStatus}
                                                  onValueChange={(e) => changeColumn("invoiceStatus", e)} size="sm">وضعیت
                                            فاکتور</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.agentId}
                                                  onValueChange={(e) => changeColumn("agentId", e)} size="sm">شناسه
                                            نمایندگی</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.agentName}
                                                  onValueChange={(e) => changeColumn("agentName", e)} size="sm">نام
                                            نمایندگی</Checkbox>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.agentManager}
                                                  onValueChange={(e) => changeColumn("agentManager", e)} size="sm">مدیر
                                            نمایندگی</Checkbox>
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <Checkbox color="danger" isSelected={columns.agentPhone}
                                                  onValueChange={(e) => changeColumn("agentPhone", e)} size="sm">تلفن
                                            نمایندگی</Checkbox>
                                    </div>


                                </Grid>


                                <Grid xs={6}>
                                    <div style={{padding: 10}}>
                                        <Typography variant="caption">
                                            {message}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={submitData} isLoading={isSending}>
                                دریافت

                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </div>
};

export default Invoices;
