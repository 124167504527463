import {useEffect, useState} from "react";
import {BrowserRouter as Router, Routes, Route, Navigate, useNavigate} from "react-router-dom";
import Login from "./pages/Login";
import {Login as ALP} from "./pages/agent/Login";
import Main from "./pages/Main";
import {Main as AM} from "./pages/agent/Main";
import {NextUIProvider} from "@nextui-org/react";
import {useAdminAuth} from "./context/AdminAuthContext";
import Customers from "./pages/Customers";
import Users from "./pages/Users";
import Agents from "./pages/Agents";
import AgentUsers from "./pages/AgentUsers";
import Invoices from "./pages/Invoices";
import {useAgentUserAuth} from "./context/AgentUserAuthContext";
import Lottery from "./pages/Lottery";
import Invoice from "./pages/Invoice";
import Dashboard from "./pages/Dashboard";
import Province from "./pages/Province";
import City from "./pages/City";

function App() {
    const [render, setRender] = useState(false);


    useEffect(() => {
        setRender(true)
    }, []);

    return (
        // <div className="App">
        //   <DICOMViewer dicomUrl="path_to_your_dicom_file.dcm" />
        // </div>
        <div>
            {render && <Router>

                <InnerRoutes/>

            </Router>}
        </div>

    );
}

export default App;


const InnerRoutes = () => {
    const navigate = useNavigate();

    return <NextUIProvider navigate={navigate}>
        <Routes>
            <Route element={
                <PublicRoute>
                    <Login/>
                </PublicRoute>
            } path={"/login"} exact/>

            <Route element={
                <PublicRoute2>
                    <ALP/>
                </PublicRoute2>
            } path={"/agent/login"} exact/>
            <Route path={'/agent'} element={
                <PrivateRoute2>
                    <AM/>
                </PrivateRoute2>
            }>
                <Route path="/agent/user" element={<AgentUsers/>} exact/>
                <Route path="/agent/invoices" element={<Invoices/>} exact/>
                <Route path="/agent/invoice/:id" element={<Invoice/>} exact/>

            </Route>
            <Route path={'/'} element={
                <PrivateRoute>
                    <Main/>
                </PrivateRoute>
            }>

                <Route path="/customer" element={<Customers/>} exact/>
                <Route path="/user" element={<Users/>} exact/>
                <Route path="/agents" element={<Agents/>} exact/>
                <Route path="/lottery" element={<Lottery/>} exact/>
                <Route path="/user-agent/:id" element={<AgentUsers/>} exact/>
                <Route path="/invoices-agent/:id" element={<Invoices/>} exact/>
                <Route path="/invoices" element={<Invoices/>} exact/>
                <Route path="/invoice/:id" element={<Invoice/>} exact/>
                <Route path="/" element={<Dashboard/>} exact/>
                <Route path="/province" element={<Province/>} exact/>
                <Route path="/city/:id" element={<City/>} exact/>


            </Route>

        </Routes>
    </NextUIProvider>
}

const PrivateRoute = ({children}) => {
    const auth = useAdminAuth();
    return auth.isAuthenticated ? children : <Navigate to="/login"/>;
}

const PublicRoute = ({children}) => {
    const auth = useAdminAuth();
    return auth.isAuthenticated ? <Navigate to={`/`}/> : children;
}

const PrivateRoute2 = ({children}) => {
    const auth = useAgentUserAuth();
    return auth.isAuthenticated ? children : <Navigate to="/agent/login"/>;
}

const PublicRoute2 = ({children}) => {
    const auth = useAgentUserAuth();
    return auth.isAuthenticated ? <Navigate to={`/agent`}/> : children;
}
