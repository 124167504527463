import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Grid, Typography} from "@mui/material";
import {
    Button,
    Chip,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader, Pagination,
    Spinner,
    Tooltip
} from "@nextui-org/react";
import Icon from "../assets/component/Icon";
import {convertErrorObject, json2QueryString} from "../utils/converts";
import Table from "../assets/component/table/Table";
import tableStyles from "../assets/style/table.module.scss";
import {UserApi} from "../api/UserApi";

const Users = () => {

    const [rows, setRows] = useState([]);
    const [currentPage] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({status: false, id: 0});
    const [user, setUser] = useState({
        name: "",
        password: "",
        mail: "",
        id: "",
        open: false
    });
    const [inputErrors, setInputErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [pageCount, setPageCount] = useState(1);
    const navigate = useNavigate();


    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({...openDeleteDialog, status: false});
    };
    const handleOpenDeleteDialog = (id) => {
        setOpenDeleteDialog({status: true, id: id});
    };
    const headers = [
        {
            title: "شناسه",
            cellRender: (row) => <div>
                <Typography variant="body2">
                    {row.id}
                </Typography>
            </div>
        },
        {
            title: "نام",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded">{row.name}</Chip>
            </div>
        },



        {
            title: "پست الکترونیکی",
            cellRender: (row) => <div>
                <Chip color="danger" variant="faded"
                      startContent={<Icon iconName="HiOutlineMail" type="hi"/>}>{row.mail}</Chip>
            </div>
        },


        {
            title: "عملیات",
            cellRender: (row) => <div>
                <Tooltip content={"ویرایش کاربر"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenUpdateDialog(row)}>
                        <Icon iconName='MdModeEdit' type="md"/>
                    </Button>
                </Tooltip>

                <Tooltip content={"حذف کاربر"} placement="top">
                    <Button size="sm" isIconOnly variant="light" color="danger" radius="lg"
                            onClick={() => handleOpenDeleteDialog(row.id)}>
                        <Icon iconName='MdOutlineDeleteOutline' type="md"/>
                    </Button>
                </Tooltip>

            </div>
        },

    ];


    const handleCloseDialog = () => {
        setUser({
            name: "",
            password: "",
            mail: "",
            id: "",
            open: false
        });

    }

    const handleOpenNewDialog = () => {
        setUser({
            name: "",
            password: "",
            mail: "",
            id: "",
            open: true
        });
    }

    const handleOpenUpdateDialog = (row) => {
        setUser({
            name: row.name,
            password: "",
            mail: row.mail,
            id: row.id,
            open: true
        });
    }

    const fetchData = async (page) => {
        setLoading(true);
        const response = ({data}) => {
            setLoading(false);
            setRows(data.data);
            setPageCount(parseInt(data.last_page));

        }

        const error = () => {
            setLoading(false);
        }

        await UserApi.getAll(`page=${page}&attr=${json2QueryString({with: []})}`, response, error)
    }



    const deleteUser = async () => {
        const response = ({data}) => {

            enqueueSnackbar("با موفقیت حذف شد.", {variant: "success"})
            setOpenDeleteDialog({status: false, id: 0});
            setIsSending(false);

            if (parseInt(currentPage.get("page")) === 1)
                (async () => {
                    await fetchData(1);
                })();
            else navigate(`/user?page=1`);
        }

        const error = (e) => {
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
            setIsSending(false)

        }
        setIsSending(true);

        await UserApi.delete(openDeleteDialog.id, response, error)
    }





    useEffect(() => {
        (async () => {
            await fetchData(currentPage.get("page") ? currentPage.get("page") : 1);
        })();
    }, [currentPage]);

    const submitData = async () => {
        const response = ({data}) => {
            setIsSending(false)

            if (user.id) {
                let array = Array.from(rows);
                let index = rows.findIndex(row => parseInt(row.id) === parseInt(user.id));
                if (index > -1)
                    array.splice(index, 1, data);
                setRows(array);
                enqueueSnackbar("با موفقیت بروزرسانی شد.", {variant: "success"});
            } else {
                enqueueSnackbar("با موفقیت افزوده شد.", {variant: "success"});
                setRows(prevState => [...prevState, data]);
            }

            handleCloseDialog();
        }

        const error = (e) => {
            setIsSending(false)
            if (e.response && e.response.status && e.response.status === 422) {
                setInputErrors(convertErrorObject(e.response.data.errors))
                enqueueSnackbar(e.response.data.message, {variant: "error"})
            } else if (e.response && e.response.status && e.response.status === 403) {
                enqueueSnackbar("دسترسی مجاز نیست.", {variant: "error"})
            } else
                enqueueSnackbar(e, {variant: "error"});
        }
        setIsSending(true)

        if (user.id)
            await UserApi.update(user.id, {
                payload: {
                    name: user.name,
                    mail: user.mail,
                    password: user.password
                }
            }, response, error);
        else
            await UserApi.insert({
                payload: {
                    name: user.name,
                    mail: user.mail,
                    password: user.password
                }
            }, response, error)
    }

    return <div>
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="body1">
                    کاربران
                </Typography>
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                {/*<Input*/}
                {/*    type="type"*/}
                {/*    label=""*/}
                {/*    className="w-full"*/}
                {/*    size="sm"*/}
                {/*    startContent={<Icon type="tb" iconName="TbSearch"/>}*/}
                {/*/>*/}
            </Grid>

            <Grid xs={6} style={{marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Button variant="light" color="danger"
                            onClick={() => handleOpenNewDialog()}
                            startContent={<Icon iconName="IoIosAdd" type="io"/>}>
                        کاربر جدید
                    </Button>
                </div>
            </Grid>

            <Grid xs={12}>
                <Table
                    classNames={{base: tableStyles.base, table: tableStyles.table}}
                    rows={rows}
                    loadingState={loading}
                    emptyContentMessage={"No User"}
                    loadingContent={<Spinner color="danger" label="Loading..."/>}
                    headers={headers}
                    bottomContent={
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Pagination
                                total={pageCount}
                                page={currentPage["page"] ? parseInt(currentPage["page"]) : 1}
                                onChange={e => navigate(`/user?page=${e}`)}
                                size="sm"
                                color="danger"
                                variant="light"/>

                        </div>
                    }/>
            </Grid>
        </Grid>
        <Modal
            size="sm"
            isOpen={openDeleteDialog.status}
            onClose={handleCloseDeleteDialog}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">حذف کاربر</ModalHeader>
                        <ModalBody>
                            <p>
                                آیا از حذف کاربر مطمئن هستید؟
                            </p>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={deleteUser} isLoading={isSending}>
                                حذف
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>

        <Modal
            isOpen={user.open}
            onOpenChange={handleCloseDialog}
            placement="top-center"
            size="3xl"
            scrollBehavior="inside"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1">کاربر  {user.id ? "بروزرسانی" : "جدید"}   </ModalHeader>
                        <ModalBody>

                            <Grid container spacing={1}>
                                <Grid xs={6}>
                                    <div style={{marginTop: 10}}>
                                        <Input
                                            label="نام"
                                            value={user.name}
                                            onValueChange={e => setUser({...user, name: e})}
                                            errorMessage={inputErrors.name && inputErrors.name.length ? inputErrors.name[0] : ''}
                                            isInvalid={inputErrors.name}
                                        />
                                    </div>

                                    <div style={{marginTop: 10}}>

                                        <Input
                                            label="رمز عبور"
                                            value={user.password}
                                            type="password"
                                            onValueChange={e => setUser({...user, password: e})}
                                            errorMessage={inputErrors.password && inputErrors.password.length ? inputErrors.password[0] : ''}
                                            isInvalid={inputErrors.password}
                                        />

                                    </div>
                                    <div style={{marginTop: 10}}>

                                        <Input
                                            label="پست الکترونیکی"
                                            type="text"
                                            value={user.mail}
                                            onValueChange={e => setUser({...user, mail: e})}
                                            errorMessage={inputErrors.mail && inputErrors.mail.length ? inputErrors.mail[0] : ''}
                                            isInvalid={inputErrors.mail}
                                        />
                                    </div>

                                </Grid>


                                <Grid xs={6}>




                                </Grid>
                            </Grid>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                انصراف
                            </Button>
                            <Button color="danger" onPress={submitData} isLoading={isSending}>
                                {user.id ? "بروزرسانی" : "اضافه کردن"}

                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </div>
};

export default Users;